import React, { useState, FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MainTemplate from '../components/MainTemplate';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Input from '../components/Input';
import { useTheme } from '@mui/material';
import { PhoneNumberInput } from '../components/Input';
import {
  initialUserData,
  PageProps,
  Pages,
  SetUserDataValue,
  unknownShareAppName,
  UserData
} from '../App';

interface LandingProps {
  setUserData: (value: UserData | SetUserDataValue) => void;
  setServerErrorMessage: (value: string) => void;
}

enum States {
  NeedsScan = 'needs_scan',
  NeedsShare = 'needs_share',
  NeedsScanAndShare = 'needs_scan_and_share',
  AllDone = 'all_done',
  NeedsRegister = 'needs_register'
}

enum InputTypes {
  Email = 'email',
  Phone = 'phone'
}

type InputType = InputTypes.Email | InputTypes.Phone;

const Landing: FC<PageProps & LandingProps> = ({
  setPage,
  userData,
  setUserData,
  startExperience,
  setServerErrorMessage
}) => {
  const [error, setError] = useState('Invalid phone number');
  const [isTouched, setIsTouched] = useState(false);
  const [inputType, setInputType] = useState<InputType>(InputTypes.Phone);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsTouched(true);

    if (error) {
      return;
    }
    setIsLoading(true);
    const res: any = await startExperience(false);
    setIsLoading(false);

    if (!res) return;

    const defaultUserData = {
      ...initialUserData,
      email: userData.email,
      phone: userData.phone
    };

    const userDataUpdate = { ...defaultUserData };

    if ('status' in res && res.status === 200) {
      if ('data' in res) {
        // set data for future calls
        setUserData(defaultUserData);
      }

      const state: string = res?.data?.state ?? Pages.Error;

      const share_privacy_policy: string =
        res?.data?.privacy_url ?? 'https://www.sizestream.com/privacypolicy';

      const share_app_name: string = res?.data?.app_name ?? unknownShareAppName;

      if (state == States.NeedsScan) {
        userDataUpdate.needs_scan = true;
        userDataUpdate.share_app_name = share_app_name;
        // if they only need to scan take to started
        setPage(Pages.Started);
      } else if (state == States.NeedsShare) {
        userDataUpdate.needs_share = true;
        userDataUpdate.share_privacy_url = share_privacy_policy;
        userDataUpdate.share_app_name = share_app_name;
        // if they need to share then go to register page to display share agreement
        setPage(Pages.Register);
      } else if (state == States.NeedsScanAndShare) {
        userDataUpdate.needs_scan = true;
        userDataUpdate.needs_share = true;
        userDataUpdate.share_privacy_url = share_privacy_policy;
        userDataUpdate.share_app_name = share_app_name;

        setPage(Pages.Register);
      } else if (state == States.NeedsRegister) {
        userDataUpdate.needs_register = true;
        userDataUpdate.needs_scan = true;
        userDataUpdate.needs_share = false;
        // check if share information was passed back, if so then the user is invited to share with this company
        if (share_privacy_policy && share_app_name) {
          userDataUpdate.needs_share = true;
          userDataUpdate.share_privacy_url = share_privacy_policy;
          userDataUpdate.share_app_name = share_app_name;
        }

        setPage(Pages.Register);
      } else if (state == States.AllDone) {
        // if they don't need to do anything they are all done
        setPage(Pages.Done);
      } else {
        // otherwise send them to error screen
        setPage(Pages.Error);
      }
    } else {
      // otherwise send them to error screen
      const error_detail: string =
        res?.data?.detail ?? 'No additional info available.';
      setServerErrorMessage(error_detail);

      setPage(Pages.Error);
    }
    setUserData(userDataUpdate);
  };

  const phoneNumberHandler = (value: string, data: any) => {
    setUserData((prev: UserData) => ({ ...prev, phone: value }));
    const correctLength = data.format.match(/\./g).length;
    if (correctLength !== value.length) {
      setError('Invalid phone number');
    } else {
      setError('');
    }
  };

  const emailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserData((prev: UserData) => ({ ...prev, email: event.target.value }));
  };

  const switchInputType = () => {
    setUserData(initialUserData);
    if (inputType === InputTypes.Phone) {
      setError('');
      setInputType(InputTypes.Email);
    } else {
      setError('Invalid phone number');
      setInputType(InputTypes.Phone);
    }
    setIsTouched(false);
  };

  return (
    <MainTemplate>
      <Button
        variant="text"
        sx={{
          position: 'absolute',
          right: '10px',
          top: '10px',
          textTransform: 'none'
        }}
        onClick={switchInputType}
      >
        {inputType === InputTypes.Phone ? 'Or email?' : ' Or phone?'}
      </Button>
      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
              padding: '0 30px'
            }
          }}
        >
          Enter your{' '}
          {inputType === InputTypes.Phone ? ' phone number' : 'email'} and
          we&apos;ll send a link so you can start measuring your fit!
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <form onSubmit={submitHandler}>
            {inputType === InputTypes.Phone ? (
              <PhoneNumberInput
                label="Phone"
                value={userData.phone}
                handleChange={phoneNumberHandler}
                isTouched={isTouched}
                setIsTouched={setIsTouched}
                sx={{ marginBottom: 2 }}
                error={!!error}
                helperText={error}
                disabled={isLoading}
              />
            ) : (
              <Input
                value={userData.email}
                onChange={emailHandler}
                label="Email"
                name="email"
                sx={{ marginBottom: 2 }}
                type="email"
                required
                disabled={isLoading}
              />
            )}
            <LoadingButton
              type="submit"
              sx={{ marginBottom: 2, background: '#fff' }}
              color="inherit"
              variant="outlined"
              loading={isLoading}
            >
              Continue
            </LoadingButton>
          </form>
          {userData.phone && (
            <Typography variant="subtitle2">SMS charges may apply</Typography>
          )}
        </Box>
      </Box>
    </MainTemplate>
  );
};

export default Landing;
